import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  ValveConnect,
  AftercoolerFan,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiInfo,
  MultiColInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function MOBScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(2);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Mountain Operations">
            <InfoControlRow>
              <MultiSetpointControl
                title="Wildfire"
                styles="padding: 2px 32px 2px 10px;"
                settings
                hidden
                pidData={locationEquipment2?.Wildfire}
                labels={['TB Low', 'TB High', 'TB Start Timer', 'TB Stop Timer', 'RR Low', 'RR High', 'RR Start Timer', 'RR Stop Timer']}
                statistics={[
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.stop_timer_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.stop_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.tb_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.stop_timer_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr1_rr2_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer_setpoint?.id}`,
                ]}
                precision={[1, 1, 0, 0, 1, 1, 0, 0]}
                IOValueKeys={{
                  autoManual: 'manual_auto',
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                buttons
              />
              <MultiColInfo
                title="Wildfire Pump Timers"
                firstColSubtitles={['TB Next Start', 'TB Next Stop']}
                secondColSubtitles={['RR Next Start', 'RR Next Stop']}
                firstColStatistics={[
                  locationEquipment2?.Wildfire?.ioValues?.start_timer?.value,
                  locationEquipment2?.Wildfire?.ioValues?.stop_timer?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.Wildfire?.ioValues?.rr_start_timer?.value,
                  locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.Wildfire?.ioValues?.start_timer?.ioValueType?.units,
                  locationEquipment2?.Wildfire?.ioValues?.stop_timer?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.Wildfire?.ioValues?.rr_start_timer?.ioValueType?.units,
                  locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.Wildfire?.ioValues?.start_timer?.id,
                  locationEquipment2?.Wildfire?.ioValues?.stop_timer?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.Wildfire?.ioValues?.rr_start_timer?.id,
                  locationEquipment2?.Wildfire?.ioValues?.rr_stop_timer?.id,
                ]}
              />
              <MultiSetpointControl
                title="Tempest"
                styles="padding: 2px 32px 2px 10px;"
                settings
                hidden
                pidData={locationEquipment2?.Tempest}
                labels={['TW Low', 'Start Timer', 'TW High', 'Stop Timer']}
                statistics={[
                  `${locationEquipment2?.Tempest?.ioValues?.tw_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Tempest?.ioValues?.start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Tempest?.ioValues?.tw_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Tempest?.ioValues?.stop_timer_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment2?.Tempest?.ioValues?.tw_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Tempest?.ioValues?.start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Tempest?.ioValues?.tw_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Tempest?.ioValues?.stop_timer_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment2?.Tempest?.ioValues?.tw_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Tempest?.ioValues?.start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Tempest?.ioValues?.tw_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Tempest?.ioValues?.stop_timer_setpoint?.id}`,
                ]}
                precision={[1, 0, 1, 0]}
                IOValueKeys={{
                  autoManual: 'manual_auto',
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                buttons
              />
              <MultiInfo
                title="Tempest Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Tempest?.ioValues?.start_timer?.value,
                  locationEquipment2?.Tempest?.ioValues?.stop_timer?.value,
                ]}
                ids={[
                  locationEquipment2?.Tempest?.ioValues?.start_timer?.id,
                  locationEquipment2?.Tempest?.ioValues?.stop_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Tempest?.ioValues?.start_timer?.ioValueType?.units,
                  locationEquipment2?.Tempest?.ioValues?.stop_timer?.ioValueType?.units,
                ]}
              />
              <MultiSetpointControl
                title="Roadrunner"
                styles="padding: 2px 32px 2px 10px;"
                settings
                hidden
                pidData={locationEquipment2?.Roadrunner}
                labels={[
                  'WCW Low',
                  'L9W Low',
                  'WCB Low',
                  '7W Low',
                  '7AW Low',
                  'Start Timer',
                  'WCW High',
                  'L9W High',
                  'WCB High',
                  '7W High',
                  '7AW High',
                  'Stop Timer',
                ]}
                statistics={[
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_low_level_setpoint']?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_low_level_setpoint']?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_high_level_setpoint']?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_high_level_setpoint']?.value}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.stop_timer_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_low_level_setpoint']?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_low_level_setpoint']?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_high_level_setpoint']?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_high_level_setpoint']?.ioValueType?.units}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.stop_timer_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_low_level_setpoint']?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_low_level_setpoint']?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcw_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.l9w_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.wcb_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7w_high_level_setpoint']?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.['7aw_high_level_setpoint']?.id}`,
                  `${locationEquipment2?.Roadrunner?.ioValues?.stop_timer_setpoint?.id}`,
                ]}
                precision={[1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0]}
                IOValueKeys={{
                  autoManual: 'manual_auto',
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                buttons
              />
              <MultiInfo
                title="Roadrunner Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Roadrunner?.ioValues?.start_timer?.value,
                  locationEquipment2?.Roadrunner?.ioValues?.stop_timer?.value,
                ]}
                ids={[
                  locationEquipment2?.Roadrunner?.ioValues?.start_timer?.id,
                  locationEquipment2?.Roadrunner?.ioValues?.stop_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Roadrunner?.ioValues?.start_timer?.ioValueType?.units,
                  locationEquipment2?.Roadrunner?.ioValues?.stop_timer?.ioValueType?.units,
                ]}
              />
              <MultiSetpointControl
                title="Upper"
                styles="padding: 2px 32px 2px 10px;"
                settings
                hidden
                pidData={locationEquipment2?.Upper}
                labels={['BW Low', 'BT Low', 'MA Low', 'Start Timer', 'BW High', 'BT High', 'MA High', 'Stop Timer']}
                statistics={[
                  `${locationEquipment2?.Upper?.ioValues?.bw_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.bw_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Upper?.ioValues?.stop_timer_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment2?.Upper?.ioValues?.bw_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.bw_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Upper?.ioValues?.stop_timer_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment2?.Upper?.ioValues?.bw_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.bw_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.bt_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.ma_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Upper?.ioValues?.stop_timer_setpoint?.id}`,
                ]}
                precision={[1, 1, 1, 0, 1, 1, 1, 0]}
                IOValueKeys={{
                  autoManual: 'manual_auto',
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                buttons
              />
              <MultiInfo
                title="Upper Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Upper?.ioValues?.start_timer?.value,
                  locationEquipment2?.Upper?.ioValues?.stop_timer?.value,
                ]}
                ids={[
                  locationEquipment2?.Upper?.ioValues?.start_timer?.id,
                  locationEquipment2?.Upper?.ioValues?.stop_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Upper?.ioValues?.start_timer?.ioValueType?.units,
                  locationEquipment2?.Upper?.ioValues?.stop_timer?.ioValueType?.units,
                ]}
              />
              <MultiSetpointControl
                title="Lower"
                styles="padding: 2px 32px 2px 10px;"
                settings
                hidden
                pidData={locationEquipment2?.Lower}
                labels={['MB Low', 'LCV Low', 'Start Timer', 'MB High', 'LCV High', 'Stop Timer']}
                statistics={[
                  `${locationEquipment2?.Lower?.ioValues?.mb_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_low_level_setpoint?.value}`,
                  `${locationEquipment2?.Lower?.ioValues?.start_timer_setpoint?.value}`,
                  `${locationEquipment2?.Lower?.ioValues?.mb_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_high_level_setpoint?.value}`,
                  `${locationEquipment2?.Lower?.ioValues?.stop_timer_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment2?.Lower?.ioValues?.mb_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_low_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Lower?.ioValues?.start_timer_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Lower?.ioValues?.mb_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_high_level_setpoint?.ioValueType?.units}`,
                  `${locationEquipment2?.Lower?.ioValues?.stop_timer_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment2?.Lower?.ioValues?.mb_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_low_level_setpoint?.id}`,
                  `${locationEquipment2?.Lower?.ioValues?.start_timer_setpoint?.id}`,
                  `${locationEquipment2?.Lower?.ioValues?.mb_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Lower?.ioValues?.lcv_high_level_setpoint?.id}`,
                  `${locationEquipment2?.Lower?.ioValues?.stop_timer_setpoint?.id}`,
                ]}
                precision={[1, 1, 0, 1, 1, 0]}
                IOValueKeys={{
                  autoManual: 'manual_auto',
                  autoMode: 'manual_auto',
                  manualMode: 'manual_auto',
                }}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                buttons
              />
              <MultiInfo
                title="Lower Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Lower?.ioValues?.start_timer?.value,
                  locationEquipment2?.Lower?.ioValues?.stop_timer?.value,
                ]}
                ids={[
                  locationEquipment2?.Lower?.ioValues?.start_timer?.id,
                  locationEquipment2?.Lower?.ioValues?.stop_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Lower?.ioValues?.start_timer?.ioValueType?.units,
                  locationEquipment2?.Lower?.ioValues?.stop_timer?.ioValueType?.units,
                ]}
              />
              <MultiColInfo
                title="Water Levels"
                firstColSubtitles={['Wildfire Cistern', 'Tempest Tank', 'Roadrunner Cistern']}
                secondColSubtitles={['Upper Cistern', 'Lower Cistern']}
                firstColStatistics={[
                  locationEquipment2?.Other?.ioValues?.wildfire_water_level?.value,
                  locationEquipment2?.Other?.ioValues?.tempest_water_level?.value,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_level?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.Other?.ioValues?.upper_cistern_water_level?.value,
                  locationEquipment2?.Other?.ioValues?.lower_cistern_water_level?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.Other?.ioValues?.wildfire_water_level?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.tempest_water_level?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_level?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.Other?.ioValues?.upper_cistern_water_level?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.lower_cistern_water_level?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.Other?.ioValues?.wildfire_water_level?.id,
                  locationEquipment2?.Other?.ioValues?.tempest_water_level?.id,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_level?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.Other?.ioValues?.upper_cistern_water_level?.id,
                  locationEquipment2?.Other?.ioValues?.lower_cistern_water_level?.id,
                ]}
                firstColPrecisions={[1, 1, 1]}
                secondColPrecisions={[1, 1]}
              />
              <MultiInfo
                title="Room Temperatures"
                subtitles={['Tempest', 'Roadrunner', '7 Well', 'Lower Pit']}
                statistics={[
                  useCelsius ? locationEquipment2?.Other?.ioValues?.tempest_room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.tempest_room_temperature?.value,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature?.value,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.['7well_room_temperature_alternate']?.value : locationEquipment2?.Other?.ioValues?.['7well_room_temperature']?.value,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature?.value,
                ]}
                labels={[
                  useCelsius ? locationEquipment2?.Other?.ioValues?.tempest_room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.tempest_room_temperature?.ioValueType?.units,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature?.ioValueType?.units,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.['7well_room_temperature_alternate']?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.['7well_room_temperature']?.ioValueType?.units,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature?.ioValueType?.units,
                ]}
                ids={[
                  useCelsius ? locationEquipment2?.Other?.ioValues?.tempest_room_temperature_alternate?.id : locationEquipment2?.Other?.ioValues?.tempest_room_temperature?.id,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature_alternate?.id : locationEquipment2?.Other?.ioValues?.roadrunner_room_temperature?.id,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.['7well_room_temperature_alternate']?.id : locationEquipment2?.Other?.ioValues?.['7well_room_temperature']?.id,
                  useCelsius ? locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature_alternate?.id : locationEquipment2?.Other?.ioValues?.lower_pit_room_temperature?.id,
                ]}
                precisions={[1, 1, 1, 1]}
              />
              <MultiColInfo
                title="Water Pressures"
                firstColSubtitles={['Tempest', 'Roadrunner', 'White Cap', '7 Well']}
                secondColSubtitles={['MA', 'Barker Well', 'South Ridge']}
                firstColStatistics={[
                  locationEquipment2?.Other?.ioValues?.tempest_water_pressure?.value,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_pressure?.value,
                  locationEquipment2?.Other?.ioValues?.whitecap_water_pressure?.value,
                  locationEquipment2?.Other?.ioValues?.['7well_water_pressure']?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.Other?.ioValues?.ma_water_pressure?.value,
                  locationEquipment2?.Other?.ioValues?.barker_water_pressure?.value,
                  locationEquipment2?.Other?.ioValues?.southridge_water_pressure?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.Other?.ioValues?.tempest_water_pressure?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_pressure?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.whitecap_water_pressure?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.['7well_water_pressure']?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.Other?.ioValues?.ma_water_pressure?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.barker_water_pressure?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.southridge_water_pressure?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.Other?.ioValues?.tempest_water_pressure?.id,
                  locationEquipment2?.Other?.ioValues?.roadrunner_water_pressure?.id,
                  locationEquipment2?.Other?.ioValues?.whitecap_water_pressure?.id,
                  locationEquipment2?.Other?.ioValues?.['7well_water_pressure']?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.Other?.ioValues?.ma_water_pressure?.id,
                  locationEquipment2?.Other?.ioValues?.barker_water_pressure?.id,
                  locationEquipment2?.Other?.ioValues?.southridge_water_pressure?.id,
                ]}
                firstColPrecisions={[0, 0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2], language)} // plcId 15: OldRiver
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
            </InfoControlRow>
            <PumpsRowMedium>
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['T-WP'], name: 'Tempest Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['T-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['T-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['T-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['T-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['T-BP'], name: 'Tempest Booster' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['T-BP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['T-BP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['T-BP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['T-BP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <AftercoolerFan
                // pipes
                // pipeAbove="middle"
                // pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                imgStyle={`height: 94px; margin-left: -27px; @media only screen and (max-width: ${theme.mobileBreakpoint}px) {margin: 30px 0px 30px 0px;}`}
                buttonsMinWidth="42px"
                controlWidth="98px"
                controlPositionTop="-50px"
                controlPositionLeft="-20px"
                fanData={{
                  ...locationEquipment2?.['T-RP'],
                  name: 'Tempest Radon',
                  state: locationEquipment2?.['T-RP']?.status,
                  fanColor: locationEquipment2?.['T-RP']?.status === 1 ? 'green' : '',
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  autoStart: 'manual_auto',
                  autoStop: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['T-RP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['T-RP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['T-RP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['T-RP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['RR-TP1'], name: 'RR Transfer 1' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['RR-TP1']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['RR-TP1']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['RR-TP1']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['RR-TP1']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['RR-TP2'], name: 'RR Transfer 2' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['RR-TP2']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['RR-TP2']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['RR-TP2']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['RR-TP2']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowMedium>
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['WC-WP'], name: 'White Cap Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['WC-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['WC-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['WC-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['WC-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['L9-WP'], name: 'L9 Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['L9-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['L9-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['L9-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['L9-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['WC-BP'], name: 'White Cap Booster' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['WC-BP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['WC-BP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['WC-BP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['WC-BP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['7-WP'], name: '7 Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['7-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['7-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['7-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['7-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['7A-WP'], name: '7A Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['7A-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['7A-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['7A-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['7A-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowMedium>
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['B-WP'], name: 'Barker Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['B-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['B-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['B-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['B-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['B-TP'], name: 'Barker Transfer' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['B-TP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['B-TP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['B-TP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['B-TP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['MA-WP'], name: 'MA Well' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['MA-WP']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['MA-WP']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['MA-WP']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['MA-WP']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                noPipes
                localRemote
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment2?.['MB-WP3'], name: 'MB Well3' }}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'stop_start',
                  stopped: 'stop_start',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                buttonText2="Man."
                buttonText1="Auto"
                writeValues={[1, 1, 1, 0]}
                readValues={[1, 0, 1, 0]}
                alarms={[]}
                statuses={[
                  {
                    id: locationEquipment2?.['MB-WP3']?.ioValues?.manual_auto?.id,
                    status: locationEquipment2?.['MB-WP3']?.ioValues?.manual_auto?.value,
                    statusKey: {
                      0: 'Manual',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.manual, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.['MB-WP3']?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.['MB-WP3']?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={[styles.flexRowBox, css`align-items: center;`]}>
                <div css={css`height: 50px;`}>
                  <ValveConnect
                    noPipe
                    binary
                    equipmentControl
                    localRemote
                    // percent
                    // percentTop="49px"
                    controlsTop="-25px"
                    controlsLeft="54px"
                    nameTop="-34px"
                    nameLeft="-5px"
                    nameWidth="46px"
                    valveStateKeys={{
                      0: 'Closed',
                      1: 'Opened',
                      2: 'In Travel',
                    }}
                    valveStateColorKeys={{
                      0: theme.secondary,
                      1: theme.onGreen,
                      2: theme.warning,
                    }}
                    IOValueKeys={{
                      opened: 'closed_opened',
                      closed: 'closed_opened',
                      open: 'open',
                      close: 'close',
                      local: 'manual_auto',
                      remote: 'manual_auto',
                    }}
                    statuses={[
                      {
                        id: locationEquipment2?.['Cistern Valve']?.ioValues?.manual_auto?.id,
                        status: locationEquipment2?.['Cistern Valve']?.ioValues?.manual_auto?.value,
                        statusKey: {
                          0: 'Manual',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.local,
                        },
                      },
                    ]}
                    writeValues={[1, 1, 1, 0]}
                    readValues={[1, 0, 1, 0]}
                    greenImageKey="Opened"
                    yellowImageKey="In Travel"
                    valveData={{ ...locationEquipment2?.['Cistern Valve'], name: 'Lower Cistern' }}
                    setIOValue={props.setIOValue}
                  />
                </div>
              </div>
              <div css={[styles.flexRowBox, css`align-items: center;`]}>
                <div css={css`height: 50px;`}>
                  <ValveConnect
                    noPipe
                    binary
                    equipmentControl
                    localRemote
                    // percent
                    // percentTop="49px"
                    controlsTop="-25px"
                    controlsLeft="54px"
                    nameTop="-34px"
                    nameLeft="-5px"
                    nameWidth="46px"
                    valveStateKeys={{
                      0: 'Closed',
                      1: 'Opened',
                      2: 'In Travel',
                    }}
                    valveStateColorKeys={{
                      0: theme.secondary,
                      1: theme.onGreen,
                      2: theme.warning,
                    }}
                    IOValueKeys={{
                      opened: 'opened',
                      closed: 'closed',
                      open: 'open',
                      close: 'close',
                      local: 'manual_auto',
                      remote: 'manual_auto',
                    }}
                    statuses={[
                      {
                        id: locationEquipment2?.['Barker Valve']?.ioValues?.manual_auto?.id,
                        status: locationEquipment2?.['Barker Valve']?.ioValues?.manual_auto?.value,
                        statusKey: {
                          0: 'Manual',
                          1: 'Auto',
                        },
                        statusColorKey: {
                          0: theme.manual, // These aren't the "named" colors but I like them for this use case
                          1: theme.local,
                        },
                      },
                    ]}
                    writeValues={[1, 1, 1, 0]}
                    readValues={[1, 1, 1, 0]}
                    greenImageKey="Opened"
                    yellowImageKey="In Travel"
                    valveData={{ ...locationEquipment2?.['Barker Valve'], name: 'Barker Valve' }}
                    setIOValue={props.setIOValue}
                  />
                </div>
              </div>
            </PumpsRowMedium>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        min-height: 100dvh;
        max-height: 100dvh;
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin: 30px 0px 70px 0px;
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left: ease 0.4s, opacity ease 2.5s;
    `,
  };
};

MOBScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

MOBScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(MOBScreen);
